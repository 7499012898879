import { WalletReadyState } from '@solana/wallet-adapter-base'
import { useWallet } from '@solana/wallet-adapter-react'
import { useEffect, useState } from 'react'
import { Page } from '~/components/Page'
import { WalletContext } from '~/components/WalletContext'
import { useUserWalletList } from '~/features/wallet/api/useUserWalletList'
import { useWalletMesageSigner } from '~/hooks/useWalletMessageSigner'
import { longStringPreview } from '~/utils/formatter'
import './wallet.css'

export const WalletPage = () => {
    return (
        <Page title='Web3 Wallet' header='Web3 Wallet' className='wallet-page'>
            <div className='wallet-page__logo'></div>
            <header>
                <h1>
                    BR1: INFINITE <span className='break-before'>IS A WEB3 GAME</span>
                </h1>
                <h2>
                    TAKE FULL CONTROL <span className='break-before'>OF YOUR GAME ITEMS.</span>
                </h2>
                <h3>
                    Set up a secure game wallet{' '}
                    <span className='break-before'>to unlock the full power of BR1: INFINITE.</span>
                    <br />
                    Own your items, trade them,
                    <span className='break-before'> or sell them—it's all up to you!</span>
                </h3>
            </header>
            <div className='wallet-page__choice-container'>
                <label htmlFor='wallet-page__radio--newbie' className='wallet-page__choice wallet-page--newbie'>
                    <h3>NEW TO THIS? NO WORRIES.</h3>
                    <figure>
                        <figcaption>POWERED BY</figcaption>
                        <img src='/media/assets/icons/magic_eden__192.png' />
                    </figure>
                    <h2>LET’S GET YOU STARTED</h2>
                </label>
                <div className='wallet-page__line--newbie'></div>
                <div className='wallet-page__line--veteran'></div>

                <input type='checkbox' id='wallet-page__radio--newbie' hidden />
                <div className='wallet-page__guide wallet-page--newbie'>
                    <header>
                        HERE’S EVERYTHING <span className='break-before'>YOU NEED TO KNOW</span>
                    </header>
                    <div className='wallet-page__guide-info'>
                        <section>
                            <header>WHAT MAKES BR1: INFINITE DIFFERENT?</header>
                            <p>
                                In this game, you actually own your items! Unlike other games, your items aren’t stuck
                                in the game — they’re yours to keep, trade, or sell, even outside the game.
                            </p>
                        </section>
                        <section>
                            <header>WHAT’S A WEB3 WALLET?</header>
                            <p>
                                It’s a secure account where you keep your game items and money. Only you can access it,
                                and you’re in full control. Use it to trade, sell, or simply store your stuff!
                            </p>
                        </section>
                        <section>
                            <header>GET YOUR GAMING WALLET READY</header>
                            <p>
                                Setting up your game account is quick and easy. We recommend starting with Magic Eden
                                Wallet, our trusted investor & partner. Download it here to get started.
                            </p>
                        </section>
                        <section>
                            <header>HOW TO CONNECT YOUR WALLET TO YOUR BR1 ACCOUNT</header>
                            <p>
                                Once your account is ready, you’re good to go! Just select your wallet from the dropdown
                                below, sign the message, and you’re all set to start owning your game items. Welcome to
                                the future of gaming!
                            </p>
                        </section>
                    </div>
                </div>
                <label htmlFor='wallet-page__radio--veteran' className='wallet-page__choice wallet-page--veteran'>
                    <h3>I’M A WEB3 VETERAN</h3>
                    <figure>
                        <img src='/media/assets/icons/wallet_grouped.png' />
                    </figure>
                    <h2>CONNECT MY WALLET</h2>
                </label>
                <input type='checkbox' id='wallet-page__radio--veteran' hidden />
                <header className='wallet-page__connect-wallet__header'>CONNECT YOUR WALLET</header>
                <WalletContext>
                    <WalletConnect />
                </WalletContext>
            </div>
        </Page>
    )
}

const WalletConnect = () => {
    const { wallets, wallet, connected, connecting, select, connect, publicKey } = useWallet()
    const [selected, setSelected] = useState(undefined)
    const [isLedger, setIsLedger] = useState(false)

    const { link, processing } = useWalletMesageSigner({ isLedger })
    const { data: userWallets } = useUserWalletList()
    const connectedWallets = userWallets?.map((wallet) => wallet.pubkey) || []
    const availableWallets = wallets.filter(
        (wallet) =>
            wallet.adapter.readyState === WalletReadyState.Installed ||
            wallet.adapter.readyState === WalletReadyState.Loadable
    )

    const handleSelect = (name) => {
        select(name)
        setSelected(name)
    }

    useEffect(() => {
        if (selected && wallet?.adapter.name == selected) {
            connect().catch(() => setSelected(undefined))
        }
    }, [selected, wallet])

    return (
        <div className='wallet-page__connect-wallet'>
            <div className='wallet-page__choose-wallet'>
                {availableWallets.map((wallet) => (
                    <div
                        className='wallet-page__wallet-icon'
                        onClick={() => handleSelect(wallet.adapter.name)}
                        data-selected={wallet.adapter.name === selected}
                    >
                        <img src={wallet.adapter.icon} />
                    </div>
                ))}
            </div>
            <button
                className='wallet-page__connect-button'
                disabled={!connected || connectedWallets.includes(publicKey?.toBase58())}
                data-connected={connectedWallets.includes(publicKey?.toBase58())}
                onClick={link}
            >
                <span>{longStringPreview(publicKey?.toBase58())}</span>
                {processing
                    ? 'SIGNING...'
                    : connectedWallets.includes(publicKey?.toBase58())
                    ? 'CONNECTED'
                    : connecting
                    ? 'CONNECTING...'
                    : connected
                    ? 'SIGN MESSAGE'
                    : 'SELECT WALLET'}
            </button>
            <label className='wallet-page__is-ledger'>
                USING LEDGER?{' '}
                <input type='checkbox' disabled={!connected || connectedWallets.includes(publicKey?.toBase58())} />
            </label>
        </div>
    )
}
